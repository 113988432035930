.status {
  float: right;
  font-size: small;
  padding-right: 5px;
}

.topbar-link {
  float: left;
  color: white;
  text-decoration: none;
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  padding: 3px;
  width: 70px;
  font-size: smaller;
  border-color: rgba(133, 122, 122, 0.3);
  background-color: rgba(133, 122, 122, 0.3);
  font-variant: small-caps;
}

.topbar-link:hover {
  background-color: rgba(133, 122, 122, 0.5);
  border-color: white;
  cursor: pointer;
}

.homepage-popup {
  background-color: white;
}

.flex-row {
  display: flex;
  justify-content: space-between;
}

.flex-right {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
}

.flex-left {
  display: flex;
  justify-content: left;
  flex-wrap: wrap;
}

.topbar-left-padding {
  padding-left: 14px;
}

.dot {
  height: 7px;
  width: 7px;
  background-color: rgb(103, 247, 90);
  border-radius: 50%;
  display: inline-block;
}

.username {
  padding-left: 4px;
}

.pad-right {
  padding-right: 7px;
}

.pad-left {
  padding-left: 7px;
}

.pad-left-pan-zoom {
  padding-left: 16px;
}

.cursive-font {
  font-family: 'Gloria Hallelujah', cursive;
}

#title {
  font-family: 'Gloria Hallelujah', cursive;
  padding-left: 20px;
  padding-right: 20px;
}

.bounce {
  animation-name: bounce-animation;
  animation-duration: 3s;
  animation-iteration-count: infinite;
}

@keyframes bounce-animation {
  20% {
    transform: translateY(2%) scaleY(0.9);
  }
  40% {
    transform: translateY(-50%) scaleY(1.1);
  }
  50% {
    transform: translateY(10%) scaleY(0.8);
  }
  70% {
    transform: translateY(-5%) scaleY(1);
  }
  75%, 100% {
    transform: translateY(0) scaleY(1);
  }
}

.spin {
  width: 5px;
  height: 5x;
  animation-timing-function: linear; 
  animation-name: spin-animation;
  animation-duration: 3s;
  animation-iteration-count: infinite;
}

@keyframes spin-animation {
  from {
      transform:rotateZ(0deg);
  }
  to {
      transform:rotateZ(360deg);
  }
}

#homepage-root {
  height: 100%;
  width: 100%;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  overflow: hidden;
}

.header {
  padding: 10px;
  background-color: #292121;
  color: white;
  display: flex;
  justify-content: space-between;
  position: relative;
  z-index: 999;
}

.homepage-flex {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: calc(100% - 53.2px);
}

.sketch-column {
  flex-basis: calc(70%);
  width: 70%;
}

.filter-column {
  flex-basis: calc(20%);
  width: 20%;
}

.layer-column {
  flex-basis: calc(10%);
  width: 10%;
}

.flex-parent-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.flex-parent-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 100%;
}

.flex-child-small {
  flex: 1;
  border: 1px dashed #ccc;
}

.flex-child-big {
  flex: 2;
  border: 1px dashed #ccc;
}

#popup-header {
  padding: 5px;
  padding-left: 15px;
  padding-right: 15px;
  font-style: italic;
  background-color: rgb(241, 235, 241);
  font-size: larger;
}

#popup-text {
  padding-top: 20px;
  line-height: 150%;
}

#split-pane {
  display: flex;
}

#filter-l-ink-toggle {
  font-size: medium;
}

#filter-l-ink-toggle-container {
  background-color:rgb(250, 250, 250);
}