.stamp-inner-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 5px;
}

.stamp-outer-box {
    display: flex;
}

.stamp-clear-button {
    border: 1px solid rgba(27, 31, 35, 0.15);
    border-radius: 6px;
    box-sizing: border-box;
    color: #24292E;
    cursor: pointer;
    display: inline-block;
    font-size: 12px;
    font-weight: 400;
    list-style: none;
    padding: 6px 16px;
    margin-left: 5px;
    position: relative;
    text-align: center;
    vertical-align: middle;
    width: 50%;
}

.stamp-input {
    width: 400px;
    height: 24px;
    margin-right: 5px;
}

.tool-column {
    display: flex;
    flex-direction: column;
    margin-top: 15px;
}

.ai-dialog-header {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.prompt-input-box {
    margin-bottom: 10px;
}

#generate-button, #loading-icon {
    margin-top: 15px;
    color: rgb(225 49 90);
}

#loading-icon::after {
    content: '';
    animation: dots 2s steps(3, end) infinite;
}


@keyframes dots {
    0% { content: ''; }
    33% { content: '.'; }
    66% { content: '..'; }
    100% { content: '...'; }
}